var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('CModalExtended',{attrs:{"size":"lg","title":_vm.titleModal,"color":"dark","closeOnBackdrop":false,"show":_vm.ModalActividadClientes},on:{"update:show":function($event){_vm.ModalActividadClientes=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.titleModal))]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.closeModal}},[_vm._v("×")])]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"add","disabled":_vm.isSubmit},on:{"click":function($event){return _vm.guardar()}}},[_c('CIcon',{attrs:{"name":"checkAlt"}}),_vm._v("  "+_vm._s(_vm.$t('button.accept'))+" ")],1),_c('CButton',{attrs:{"outline":"","color":"wipe","disabled":_vm.isSubmit},on:{"click":_vm.closeModal}},[_c('CIcon',{attrs:{"name":"x"}}),_vm._v("  "),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('button.cancel')))])],1)]},proxy:true}])},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{staticClass:"text-right mt-1 pr-1",attrs:{"sm":"3"}},[_c('label',[_c('b',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v(" "+_vm._s(_vm.$t('label.activity'))+" ")])]),_c('CCol',{attrs:{"sm":"7"}},[_c('div',{staticClass:"form-group form-row"},[_c('div',{staticClass:"input-group col-sm-12 col-lg-12 input-group"},[_c('v-select',{directives:[{name:"uppercase",rawName:"v-uppercase"}],class:_vm.computedSelectClient 
                      ? 'select-client--correct' 
                      : (_vm.verifyClient ? 'select-client--error' : ''),staticStyle:{"width":"92% !important"},attrs:{"options":_vm.activityOptions,"label":"TpClientName","placeholder":_vm.$t('label.select'),"value":_vm.TpClientId,"is-valid":_vm.hasError(_vm.$v.TpClientId),"invalid-feedback":_vm.errorMessage(_vm.$v.TpClientId),"filter":_vm.fuseSearchActividades},on:{"update:value":function($event){_vm.TpClientId=$event}},model:{value:(_vm.$v.TpClientId.$model),callback:function ($$v) {_vm.$set(_vm.$v.TpClientId, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.TpClientId.$model"}}),_c('div',{staticClass:"input-group-append"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.nueva')+''+_vm.$t('label.activity'),
                        placement: 'top-end'
                        }),expression:"{\n                        content: $t('label.nueva')+''+$t('label.activity'),\n                        placement: 'top-end'\n                        }"}],staticClass:"mb-1",attrs:{"color":"add","size":"sm"},on:{"click":function($event){_vm.ActCollapse = !_vm.ActCollapse}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}})],1)],1)],1),(_vm.$v.TpClientId.$error && !_vm.$v.TpClientId.required && _vm.verifyClient)?_c('div',{staticClass:"col-sm-11 col-lg-12 text-invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('label.required'))+" ")]):_vm._e()])])],1)],1),_c('CCol',{staticClass:"mb-3",attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{staticClass:"text-right pr-1",attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2"},[_c('b',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v(" "+_vm._s(_vm.$t('label.client'))+" ")])]),_c('CCol',{attrs:{"sm":"7"}},[_c('multiselect',{staticClass:"my-1",class:_vm.isClientValid(_vm.cliVal),staticStyle:{"min-height":"33px"},attrs:{"options":_vm.clientesOptions,"multiple":true,"placeholder":_vm.$t('label.select'),"label":"name","track-by":"name","taggable":true,"is-valid":_vm.hasError(_vm.$v.ClientId),"invalid-feedback":_vm.errorMessage(_vm.$v.ClientId)},on:{"tag":_vm.addTag},model:{value:(_vm.$v.ClientId.$model),callback:function ($$v) {_vm.$set(_vm.$v.ClientId, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.ClientId.$model"}})],1),_c('CCol',{attrs:{"sm":"3"}}),_c('CCol',{attrs:{"sm":"7"}},[_c('div',{staticClass:"col-sm-12 p-0"},[_c('div',{staticClass:"text-invalid-feedback"},[_vm._v(_vm._s(_vm.cambiaTextoValidate(_vm.ClientId)))])])])],1)],1),(this.FgActApplyCompanyBranch == true)?_c('CCol',{staticClass:"mb-3",attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{staticClass:"text-right pr-1",attrs:{"sm":"3"}},[_c('label',[_c('b',{staticClass:"text-danger"},[_vm._v(" * ")]),_vm._v(" "+_vm._s(_vm.$t('label.branch')))])]),_c('CCol',{attrs:{"sm":"7"}},[_c('v-select',{class:_vm.computedSelectBranch 
                      ? 'select-client--correct' 
                      : (_vm.verifyClient ? 'select-client--error' : ''),staticStyle:{"width":"100%"},attrs:{"options":_vm.sucursalOptions,"label":"BranchName","placeholder":_vm.$t('label.select'),"value":_vm.sucursal},on:{"update:value":function($event){_vm.sucursal=$event}},model:{value:(_vm.sucursal),callback:function ($$v) {_vm.sucursal=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"sucursal"}}),(_vm.$v.sucursal.$error && !_vm.$v.sucursal.required && _vm.verifyClient)?_c('div',{staticClass:"col-sm-11 col-lg-12 pl-0 text-invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('label.required'))+" ")]):_vm._e()],1)],1)],1):_vm._e(),_c('CCol',{staticClass:"mt-3",attrs:{"sm":"12"}},[_c('ActividadCollapse',{attrs:{"collapse":_vm.ActCollapse},on:{"child-refresh":_vm.actualizaActividad}})],1),(!_vm.ActCollapse)?_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.items,"fields":_vm.fields,"loading":_vm.LoadingTabla,"column-filter":"","noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":5,"hover":"","small":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}}],null,false,1797463590)})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }